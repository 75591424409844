import React from 'react';
import NavBar from '../components/NavBar';
import Footer from '../components/Footer';

const TotalTermsAndConditions = () => {
  return (
    <>
      <NavBar />
      <div className="terms-and-conditions">
        <h1>Terms and Conditions</h1>

        <h2>1. Services Provided</h2>
        <p>
          The Website provides financial analytics, tools, and insights ("the
          Services") to help users make informed financial decisions. The
          Services include, but are not limited to, analytics on stocks,
          cryptocurrencies, and economic trends.
        </p>
        <p>
          Important: The Services are for informational purposes only and should
          not be construed as financial, investment, or legal advice. Users are
          solely responsible for their investment decisions.
        </p>

        <h2>2. No Financial Advice</h2>
        <ul>
          <li>The Website is not a licensed financial advisor, broker, or dealer.</li>
          <li>
            The analytics and insights provided are general and may not apply to
            your specific circumstances.
          </li>
          <li>
            Always consult with a qualified professional before making financial
            or investment decisions.
          </li>
        </ul>

        <h2>3. Disclaimer of Liability</h2>
        <p>
          The Website, its owners, employees, or affiliates will not be held
          liable for:
        </p>
        <ul>
          <li>
            Any financial losses, damages, or liabilities resulting from
            reliance on the Services.
          </li>
          <li>
            Investment decisions made based on the analytics or advice provided.
          </li>
          <li>Errors, inaccuracies, or omissions in the Services.</li>
        </ul>
        <p>All decisions made based on the Services are made solely at the User's risk.</p>

        <h2>4. User Responsibility</h2>
        <ul>
          <li>
            Users are encouraged to independently verify the information
            provided by the Website.
          </li>
          <li>
            Past performance of any financial instrument analyzed on the Website
            is not indicative of future results.
          </li>
        </ul>

        <h2>5. Indemnification</h2>
        <p>
          Users agree to indemnify and hold harmless the Website, its owners,
          employees, and affiliates from any claims, damages, or losses arising
          from their use of the Services or violation of these Terms.
        </p>

        <h2>6. Subscription Terms</h2>
        <ul>
          <li>
            The Website operates on a subscription basis. By subscribing, Users
            gain access to analytics and insights as outlined on the Website.
          </li>
          <li>All subscription payments are non-refundable, except as required by law.</li>
          <li>
            Unauthorized sharing or distribution of subscription content is
            prohibited and may result in termination of access.
          </li>
        </ul>

        <h2>7. Intellectual Property</h2>
        <p>
          All content, including but not limited to text, graphics, software,
          and analytics, is the intellectual property of [Your Website Name] and
          is protected by applicable copyright and trademark laws. Users may
          not reproduce or distribute this content without written permission.
        </p>

        <h2>8. Termination</h2>
        <p>
          The Website reserves the right to terminate a User's access to the
          Services at its sole discretion, including but not limited to cases
          of suspected abuse, non-compliance with these Terms, or other
          violations.
        </p>

        <h2>9. Governing Law</h2>
        <p>
          These Terms shall be governed by and construed in accordance with the
          laws of [Your State/Country]. Any disputes arising under these Terms
          shall be resolved exclusively in the courts located in [Your
          Jurisdiction].
        </p>

        <h2>10. Changes to Terms</h2>
        <p>
          The Website reserves the right to update or modify these Terms at any
          time without prior notice. Continued use of the Website following any
          changes constitutes acceptance of the revised Terms.
        </p>

        <h2>11. Contact Us</h2>
        <p>
          For questions or concerns regarding these Terms, please contact us at:
          <br />
          Email: TheFinntech.com@gmail.com
        </p>
      </div>
      <Footer />
    </>
  );
};

export default TotalTermsAndConditions;