import React from "react";
import MyTradingViewWidget from "../components/MyTradingViewWidget";
import LoggedInHeader from "../components/LoggedInHeader";

const LiveCharts = () => {
    return (
        <>
            <LoggedInHeader />
            <div style={{
                width: "99vw", // 90% of the viewport width
                height: "92vh", // 90% of the viewport height
                margin: "0 auto", // Center align horizontally
                display: "flex", // Center align widget inside
                justifyContent: "center",
                alignItems: "center",
            }}>
                <MyTradingViewWidget />
            </div>
        </>
    );
};

export default LiveCharts;
