import React from "react";
import LoggedInHeader from "../components/LoggedInHeader";
import BlogPostCreation from "../components/BlogPostCreation";


const CreateBlog = () => {



    return (
        <>
        <LoggedInHeader/>
        <BlogPostCreation/>
        </>
    )
};

export default CreateBlog;