// src/components/ReportBugPopup.js
import React, { useContext, useState } from 'react';
import '../assets/styles/ReportBugPopup.css';
import AuthLogin from '../profile/auth-login';

const ReportBugPopup = ({ onClose }) => {
  const [bugReport, setBugReport] = useState('');
  const ctx = useContext(AuthLogin);

  const handleSubmit = (e) => {
    e.preventDefault();
    // Add your submission logic here
    // console.log('Bug report submitted:', bugReport);
    submitBugHandler();
    onClose();
  };

  const submitBugHandler = async(event) => {
    // Create request body
    const requestBody = {
        "bug": bugReport
    };

    const url = `${process.env.REACT_APP_API_URL}/submitBug/${ctx.userId}`;
    const response = await fetch(url, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
      },
      body: JSON.stringify(requestBody)
    });
};

  return (
    <div className="popup-overlay">
      <div className="popup-content">
        <div className="popup-header">
          <h2>Report a Bug</h2>
          <button className="close-button" onClick={onClose}>×</button>
        </div>
        <form onSubmit={handleSubmit}>
          <textarea
            value={bugReport}
            onChange={(e) => setBugReport(e.target.value)}
            placeholder="Describe the bug you encountered..."
            className="bug-textarea"
          />
          <button type="submit" className="submit-button">
            Submit
          </button>
        </form>
      </div>
    </div>
  );
};

export default ReportBugPopup;