import React from "react";

import NavBar from "../components/NavBar";
import BlogList from "../components/BlogList";
import Footer from "../components/Footer";


const Blogs = () => {

    return (
    <>
        <NavBar/>
        <h1 className='abouth1'>Blog</h1>
        <BlogList maxDisplay={10}/>
        <Footer />
    </> 
    )

}

export default Blogs;