import React from 'react';
import { Link } from 'react-router-dom';
import '../assets/styles/NavBar.css'; 

const NavBar = () => {
  return (
    <nav className="navbar">
      <Link to="/" className="navbar-link brand-name">Finn Tech</Link>
      <Link to="/Blogs" className="navbar-link">Blog</Link>
      <Link to="/SocialMedia" className="navbar-link">Social Media</Link>
      <Link to="/Contact" className="navbar-link">Contact</Link>
      <Link to="/Pricing" className="navbar-link">Pricing</Link>
      <Link to="/About" className="navbar-link">About</Link>
      <Link to="/Login" className="navbar-link login">Login</Link>
    </nav>
  );
};

export default NavBar;
