import React from 'react';

import NavBar from "../components/NavBar";

const SocialMedia = () => {
    
    
   return (
    <>
    <NavBar/>
    <h1>Social Media Page</h1>
   </>
   )
   
}
    
export default SocialMedia;