import React, { useRef } from "react";

// a little un needed to do it like this right now
// in the future this will help control styling easier and cleaner
const Input = React.forwardRef((props, ref) => {
  const inputRef = useRef();

  return (
    <>
      {props.label}
      <br />
      <input
        ref={inputRef}
        id={props.id}
        value={props.value}
        className="inputs"
        onChange={props.onChange}
        onBlur={props.onBlur}
        type={props.type}
      />
    </>
  );
});

export default Input;
