import "./App.css";

import React from "react";
//import Container from 'react-bootstrap/Container'
//import Row from 'react-bootstrap/Row'
//import { Container } from 'react-bootstrap';
// import ClientForm from './components/ClientForm';
// import AuthLogin from "./profile/auth-login";
import AppRoutes from './routes';
import { BrowserRouter as Router } from 'react-router-dom';

function App(props) {
  // const ctx = useContext(AuthLogin);

  return (
    
    <Router>
      <AppRoutes />  {/* Use the routes defined in routes.js */}
    </Router>
  );
}

export default App;
