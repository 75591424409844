// src/components/AccountDropdown.js
import React, { useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import '../assets/styles/AccountDropdown.css';
import AuthLogin from '../profile/auth-login';

const AccountDropdown = () => {
  const navigate = useNavigate();
  const ctx = useContext(AuthLogin);

  const handleLogout = () => {
    // Add your logout logic here
    // console.log('Logging out...');
    // Example: navigate to login page
    navigate('/');
    ctx.onLogout();

  };

  return (
    <div className="account-dropdown">
      <div 
        className="dropdown-item logout"
        onClick={handleLogout}
      >
        Logout
      </div>
    </div>
  );
};

export default AccountDropdown;