import React, { useContext } from 'react';
import { useParams } from 'react-router-dom';
import Footer from '../components/Footer';
import NavBar from '../components/NavBar';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.bubble.css'; // Use bubble theme for viewing
import AuthLogin from '../profile/auth-login';
import LoggedInHeader from '../components/LoggedInHeader';
import '../assets/styles/BlogPost.css'

const BlogPost = ({ blogData }) => {
  const ctx = useContext(AuthLogin);

  if (!blogData) {
    return <div>Blog post not found</div>;
  }

  console.log(blogData);

  const renderContentBlock = (block) => {
    switch (block.type) {
      case 'text':
        return (
          <div className="blog-text-block">
            <ReactQuill 
              value={block.content}
              readOnly={true}
              theme="bubble"
            />
          </div>
        );
        
      case 'image':
        return (
          <div className="blog-image-block">
            <img 
              src={`/api/blogImages/${block.image_filename}`}
              alt={block.image_alt_text}
              style={{ maxWidth: '100%', height: 'auto' }}
            />
          </div>
        );
        
      case 'chart':
        return (
          <div className="blog-chart-block">
            <iframe
              src={`${block.chart_route}`}
              title="Chart"
              width="100%"
              height="400px"
              frameBorder="0"
            />
          </div>
        );
        
      default:
        return null;
    }
  };

  return (
    <>
      {ctx.isLoggedIn? <LoggedInHeader/> : <NavBar /> }
      <div className="blog-post-container">
        <h1>{blogData.title}</h1>
        <div className="blog-post-meta">
          <div className="post-info">
            <a href="#" className="author-name">{blogData.author}</a>
            <span className="post-date">{blogData.created_at}</span>
          </div>
          {/* <div className="social-share">
            <div className="share-button">f</div>
            <div className="share-button">t</div>
            <div className="share-button">in</div>
          </div> */}
        </div>
        <div className="blog-content">
          {blogData.contentBlocks.map((block, index) => (
            <div key={index} className="content-block">
              {renderContentBlock(block)}
            </div>
          ))}
        </div>
      </div>
      <Footer />
    </>
  );
};

export default BlogPost;