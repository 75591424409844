import React, { useContext, useState, useCallback, useEffect } from "react";
import { Route, Routes } from 'react-router-dom';
import Home from "./pages/Home";
import About from './pages/About';
import Pricing from './pages/Pricing';
import Contact from "./pages/Contact";
import SocialMedia from './pages/SocialMedia';
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard"
import AuthLogin from "./profile/auth-login";
import AccessDenied from "./pages/AccessDenied"
import Blogs from "./pages/Blogs";
import BlogPost from "./pages/BlogPost"; // You'll need to create this component
import AddCharts from "./pages/AddCharts";
import LoggedInBlogs from "./pages/LoggedInBlogs";
import Learn from "./pages/Learn";
import Books from "./pages/Books";
import LiveCharts from "./pages/LiveCharts";
import TotalTermsAndConditions from "./pages/TotalTermsAndConditions";
import SubmitIdea from "./pages/SubmitIdea";
import CreateBlog from "./pages/CreateBlog";
import Videos from "./pages/Videos";

const AppRoutes = () => {
  const ctx = useContext(AuthLogin);

  const [blogItems, setBlogs] = useState([
    { title: 'Blog post title', created_at: '2024-03-20', author: 'Author Name', likes: 156, contentBlocks: null },
    { title: 'Another blog post', created_at: '2024-03-19', author: 'Author Name', likes: 342, contentBlocks: null },
    { title: 'Interesting article', created_at: '2024-03-18', author: 'Author Name', likes: 89, contentBlocks: null },
    { title: 'Tech news update', created_at: '2024-03-17', author: 'Author Name', likes: 267, contentBlocks: null },
    { title: 'Latest developments', created_at: '2024-03-16', author: 'Author Name', likes: 178, contentBlocks: null },
    { title: 'Industry insights', created_at: '2024-03-15', author: 'Author Name', likes: 445, contentBlocks: null },
  ]);

  const fetchBlogsHandler = useCallback(async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/getallblogs`);
      const data = await response.json();
      // console.log(data);
      if (!response.ok) {
        throw new Error();
      }
  
      const loadedBlogs = [];
  
      for (const key in data) {
        // Fetch content blocks for this blog
        const blocksResponse = await fetch(
          `${process.env.REACT_APP_API_URL}/getBlogContent/${data[key].id}`
        );
        const blocksData = await blocksResponse.json();
  
        loadedBlogs.push({
          id: data[key].id,
          title: data[key].title,
          created_at: new Date(data[key].created_at).toLocaleDateString(),
          author: data[key].author,
          likes: data[key].likes,
          contentBlocks: blocksData
        });
      }
      // console.log(loadedBlogs);
      setBlogs(loadedBlogs);
    } catch (error) {
      console.log(error.message);
    }
  }, []);

  //Render once
  useEffect(() => {
    fetchBlogsHandler();
  }, [fetchBlogsHandler]);

  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/Login" element={<Login />} />
      <Route path="/About" element={<About />} />
      <Route path="/Pricing" element={<Pricing/>} />
      <Route path="/Contact" element={<Contact/>} />
      <Route path="/SocialMedia" element={<SocialMedia />} />
      <Route path="/my-dashboard" element={ctx.isLoggedIn? <Dashboard /> : <AccessDenied />} />
      <Route path="/add-charts" element={ctx.isLoggedIn? <AddCharts /> : <AccessDenied />} />
      <Route path="/blogged-in" element={ctx.isLoggedIn? <LoggedInBlogs /> : <AccessDenied />} />
      <Route path="/learn" element={ctx.isLoggedIn? <Learn /> : <AccessDenied />} />
      <Route path="/books" element={ctx.isLoggedIn? <Books /> : <AccessDenied />} />
      <Route path="/videos" element={ctx.isLoggedIn? <Videos /> : <AccessDenied />} />
      <Route path="/live-charts" element={ctx.isLoggedIn? <LiveCharts /> : <AccessDenied />} />
      <Route path="/blogs" element={<Blogs/>} />
      <Route path="/terms-and-conditions" element={<TotalTermsAndConditions/>} />
      <Route path="/submit-idea" element={<SubmitIdea/>}/>
      <Route path="/create-blog" element={ctx.isLoggedIn & ctx.role === 'admin' ? <CreateBlog/> : <AccessDenied />} />
      
      {/* Dynamic blog post routes */}
      {blogItems.map(blog => (
        <Route
          key={blog.title}
          path={`/blogs/${blog.title.replace(/ /g, '-')}`}
          element={<BlogPost blogData={blog} />}
        />
      ))}
    </Routes>
  );
};

export default AppRoutes;