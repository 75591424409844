import React from 'react';

import NavBar from "../components/NavBar";

const About = () => {
    
    
   return (
    <>
    <NavBar/>
    <h1>Landing Page</h1>
   </>
   )
   
}
    
export default About;