import React, {useContext, useEffect, useState} from 'react';
import '../assets/styles/AddCharts.css';
import LoggedInHeader from '../components/LoggedInHeader';
import Graph from '../components/Graph';
import AuthLogin from '../profile/auth-login';

const AddCharts = () => {
  const ctx = useContext(AuthLogin);
  const [charts, setCharts] = useState([]);

  useEffect(() => {
    const fetchCharts = async () => {
      try {
        const url = `${process.env.REACT_APP_API_URL}/getAllNonUserCharts/${ctx.userId}`
        // console.log(url)
        const response = await fetch(url);
        const data = await response.json();
        setCharts(data);
        // console.log(data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchCharts();
  }, [ctx.userId]);

  const handleAddToDashboard = async (chartId) => {
    try {
      // First, update the UI optimistically
      setCharts(prevCharts => prevCharts.filter(chart => chart.id !== chartId));
  
      // Create request body
      const requestBody = {
        "chartIdToAdd": chartId
      };
  
      // Debug logging
      // console.log('Request URL:', `${process.env.REACT_APP_API_URL}/deleteFromDashboard/${ctx.userId}`);
      // console.log('Request Body:', requestBody);
      // console.log('Request Body (stringified):', JSON.stringify(requestBody));
  
      // Verify chartId is not undefined or null
      if (!chartId) {
        throw new Error('chartId is required');
      }
  
      // Make API call to update the dashboard array in the database
      const url = `${process.env.REACT_APP_API_URL}/addToDashboard/${ctx.userId}`;
      const response = await fetch(url, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: JSON.stringify(requestBody)
      });
  
      // Log the full response for debugging
      // console.log('Response status:', response.status);
      const responseText = await response.text();
      // console.log('Response body:', responseText);
  
      if (!response.ok) {
        throw new Error(`Failed to update dashboard: ${responseText}`);
      }
  
      // Only try to parse as JSON if we have content
      const data = responseText ? JSON.parse(responseText) : {};
      // console.log('Update successful:', data);
  
    } catch (error) {
      console.error('Error updating dashboard:', error);
      // Show error to user
      alert(`Failed to remove chart: ${error.message}`);
      
      // Refresh the charts data in case of error
      try {
        const fetchResponse = await fetch(`${process.env.REACT_APP_API_URL}/getAllNonUserCharts/${ctx.userId}`);
        const data = await fetchResponse.json();
        setCharts(data);
      } catch (refreshError) {
        console.error('Error refreshing dashboard:', refreshError);
      }
    }
  };


  return (
   <>
   <LoggedInHeader />
    <div className="dashboard">
      <div className="dashboard-grid">
        {charts.map((charts, index) => (
          <Graph key={index} { ...charts} showPlus={true} onAdd={() => handleAddToDashboard(charts.id)}/>
        ))}
      </div>
    </div>
    </>
  );
};

export default AddCharts;