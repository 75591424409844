import React from "react";

import BlogList from "../components/BlogList";
import LoggedInHeader from "../components/LoggedInHeader";


const LoggedInBlogs = () => {

    return (
    <>
        <LoggedInHeader />
        <BlogList maxDisplay={10}/>
    </> 
    )

}

export default LoggedInBlogs;