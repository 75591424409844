import React from 'react';

import NavBar from "../components/NavBar";
import Header from "../components/Header";
import LoginInput from "../components/LoginInput";

const Login = (props) => {

  return (
    <>
      <NavBar/>
      <Header />
      <LoginInput/>
    </>
  );
};

export default Login;
