// TermsAndConditions.js

import React, { useState, useContext } from 'react';
import '../assets/styles/TermsAndConditionsPopup.css';
import AuthLogin from '../profile/auth-login';

const TermsAndConditionsPopup = ({ onAccept }) => {
  const [isChecked, setIsChecked] = useState(false);
  const ctx = useContext(AuthLogin);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const handleDoneClick = () => {
    if (isChecked) {
      onAccept();
    }
  };

  return (
    <div className="terms-and-conditions-container">
      <div className="terms-and-conditions-modal">
        <h2>Terms and Conditions</h2>
        <div className="terms-and-conditions-content">
          <p>
            Welcome to The Finn Tech. By accessing or using the 
            Website and its services, you {ctx.fullName} agree to be bound by these
            <a href="/terms-and-conditions" target="_blank" rel="noopener noreferrer">
               Terms of Service
            </a>. 
            If you do not agree to these Terms, please do not use the Website.
          </p>
          <div className="terms-and-conditions-checkbox">
            <input
              type="checkbox"
              id="terms-and-conditions-checkbox"
              checked={isChecked}
              onChange={handleCheckboxChange}
            />
            <label htmlFor="terms-and-conditions-checkbox">
              I have read and accept these terms and conditions.
            </label>
          </div>
        </div>
        <div className="terms-and-conditions-actions">
          <button
            className="terms-and-conditions-button"
            onClick={handleDoneClick}
            disabled={!isChecked}
          >
            Done
          </button>
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditionsPopup;