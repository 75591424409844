import React, {useCallback, useContext, useEffect, useState} from 'react';
import '../assets/styles/Dashboard.css';
import LoggedInHeader from '../components/LoggedInHeader';
import Graph from '../components/Graph';
import AuthLogin from '../profile/auth-login';
import TermsAndConditionsPopup from '../components/TermsAndConditionsPopup';

const Dashboard = () => {
  const ctx = useContext(AuthLogin);
  const [charts, setCharts] = useState([]);

  const FINNTECH_API_URL = process.env.REACT_APP_API_URL; 

  useEffect(() => {
    const fetchCharts = async () => {
      try {
        const url = `${FINNTECH_API_URL}/getUserDashboard/${ctx.userId}`
        // console.log(url);
        const response = await fetch(url);
        const data = await response.json();
        setCharts(data);
        // console.log(data);
      } catch (error) {
        console.log(error);
      }
    };
    fetchCharts();
  }, [ctx.userId]);

  const handleRemoveFromDashboard = async (chartId) => {
    try {
      // First, update the UI optimistically
      setCharts(prevCharts => prevCharts.filter(chart => chart.id !== chartId));
  
      // Create request body
      const requestBody = {
        "chartIdToRemove": chartId
      };
  
      // Debug logging
      // console.log('Request URL:', `${process.env.REACT_APP_API_URL}/deleteFromDashboard/${ctx.userId}`);
      // console.log('Request Body:', requestBody);
      // console.log('Request Body (stringified):', JSON.stringify(requestBody));
  
      // Verify chartId is not undefined or null
      if (!chartId) {
        throw new Error('chartId is required');
      }
  
      // Make API call to update the dashboard array in the database
      const url = `${process.env.REACT_APP_API_URL}/deleteFromDashboard/${ctx.userId}`;
      const response = await fetch(url, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        },
        body: JSON.stringify(requestBody)
      });
  
      // Log the full response for debugging
      // console.log('Response status:', response.status);
      const responseText = await response.text();
      // console.log('Response body:', responseText);
  
      if (!response.ok) {
        throw new Error(`Failed to update dashboard: ${responseText}`);
      }
  
      // Only try to parse as JSON if we have content
      const data = responseText ? JSON.parse(responseText) : {};
      // console.log('Update successful:', data);
  
    } catch (error) {
      console.error('Error updating dashboard:', error);
      // Show error to user
      alert(`Failed to remove chart: ${error.message}`);
      
      // Refresh the charts data in case of error
      try {
        const fetchResponse = await fetch(`${process.env.REACT_APP_API_URL}/getUserDashboard/${ctx.userId}`);
        const data = await fetchResponse.json();
        setCharts(data);
      } catch (refreshError) {
        console.error('Error refreshing dashboard:', refreshError);
      }
    }
  };

  const logUserAcceptedTermsAndConditions = useCallback(async (user_id) => {
    const url = `${FINNTECH_API_URL}/logUserSignedTermsAndConditions/${user_id}`;

    const response = await fetch(url, {
      method: 'GET',
      headers: {
        'Accept': 'application/json',
      }
    });
    // const response = await fetch(url, { method: 'GET', headers: { 'Content-Type': 'application/json' } });
    // const response = await fetch(url);
    // console.log(response);

    if (!response.ok) {
      throw new Error();
    }

    const data = await response.json();
    // console.log(data);


    return data; 
  }, []);

  const handleTermsAndConditionsAccept = async (event) => {
    // Perform any necessary actions when the user accepts the terms and conditions
    const log = await logUserAcceptedTermsAndConditions(ctx.userId);

    // Get current user data from localStorage
    const userData = JSON.parse(localStorage.getItem('userInfo') || '[]');
    
    // Update the values
    const updatedUserData = {
      ...userData,
      firstTimeLogin: "false",
      userAcceptedTermsAndConditions: "true"
    };
    
    // Save back to localStorage
    localStorage.setItem('userInfo', JSON.stringify(updatedUserData));

    // Update UI state
    ctx.setFirstTimeLogin(false);
    ctx.setuserAcceptedTermsAndConditions(true);
    // console.log('Terms and conditions accepted');
  };

  return (
    <>
      {(!ctx.userAcceptedTermsAndConditions) ? (
        <TermsAndConditionsPopup onAccept={handleTermsAndConditionsAccept} />
      ) : (
        <>
          <LoggedInHeader />
          <div className="dashboard">
            <div className="dashboard-grid">
              {charts.map((chart, index) => (
                <Graph
                  key={chart.id || index}
                  {...chart}
                  showPlus={false}
                  onRemove={() => handleRemoveFromDashboard(chart.id)}
                />
              ))}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Dashboard;