import React from 'react';

import '../assets/styles/Header.css'
import Logo from '../assets/images/logo.png';

const Header = () => {
  return (
    <div className='titlebar'>
      <img className='logo' alt='finntechLogo' src={Logo} />
    </div>
  );
};

export default Header;
