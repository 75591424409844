import React from "react";
import LoggedInHeader from "../components/LoggedInHeader";
import "../assets/styles/Books.css";

const Books = () => {
    const books = [
        {
          id: 1,
          title: "One Up On Wall Street",
          author: "Peter Lynch",
          description: "In easy-to-follow terminology, Lynch offers directions for sorting out the long shots from the no shots by spending just a few minutes with a company's financial statements. His advice for producing \"tenbaggers\" can turn a stock portfolio into a star performer!",
          image: "one-up-wall-street.jpg",
          link: "https://www.amazon.com/One-Up-Wall-Street-Already/dp/0743200403/"
        },
        {
          id: 2,
          title: "The Richest Man In Babylon",
          author: "George S. Clason",
          description: "The Richest Man in Babylon is a 1926 book by George S. Clason that dispenses financial advice through a collection of parables set 4,097 years earlier, in ancient Babylon. The book remains in print almost a century after the parables were originally published, and is regarded as a classic of personal financial advice.",
          image: "richest-man-babylon.jpg",
          link: "https://www.amazon.com/Richest-Man-Babylon-George-Clason/dp/1505339111/"
        },
        {
          id: 3,
          title: "The Psychology of Money",
          author: "Morgan Housel",
          description: "Doing well with money isn't necessarily about what you know. It's about how you behave. And behavior is hard to teach, even to really smart people.",
          image: "psychology-money.jpg",
          link: "https://www.amazon.com/Psychology-Money-Timeless-Lessons-Happiness/dp/0857197681/"
        },
        {
            id: 4,
            title: "You Weren't Supposed To See That",
            author: "Joshua Brown",
            description: "There are secret ways of seeing the world of finance that every investor should know. Overlooked things that tip the balance from failure to success. Hidden truths that make the critical difference.",
            image: "supposed-to-see-that.jpg",
            link: "https://a.co/d/3ID17fP"
        },
        {
            id: 5,
            title: "The Millionaire Next Door",
            author: "Thomas J. Stanley",
            description: `The Surprising Secrets of America's Wealthy is a 1996 book by Thomas J. Stanley and William D. Danko. The book is a compilation of research done by the two authors in the profiles of American millionaires. The authors compare the behaviour of those they call "UAWs" and those who are "PAWs".`,
            image: "millionaire-next-door.jpg",
            link: "https://a.co/d/aILtg86"
        }
      ];

  return (
    <>
    <LoggedInHeader />
    <div className="books-container">
      <div className="content">
        {books.map((book) => (
            <a 
            key={book.id} 
            href={book.link} 
            target="_blank" 
            rel="noopener noreferrer" 
            className="book-card"
          >
            <div className="book-image">
              <img src={book.image} alt={book.title} />
            </div>
            <div className="book-details">
              <h2>{book.title}</h2>
              <h3>{book.author}</h3>
              <p>{book.description}</p>
            </div>
          </a>
        ))}
      </div>
    </div>
    </>
  );
};

export default Books;