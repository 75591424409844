import React, { useState, useCallback, useEffect, useContext } from 'react';
import '../assets/styles/BlogList.css';
import AuthLogin from '../profile/auth-login';

const HeartIcon = ({ isLiked }) => (
  <span className={`heart-icon ${isLiked ? 'liked' : ''}`}>
    {isLiked ? '♥' : '♡'}
  </span>
);

const FINNTECH_API_URL = process.env.REACT_APP_API_URL;

const BlogItem = ({ title, created_at, author, likes, blogId }) => {  // Added blogId prop
  const ctx = useContext(AuthLogin);
  const [likeCount, setLikeCount] = useState(0);
  const [isLiked, setIsLiked] = useState(false);

  useEffect(() => {
    setLikeCount(parseInt(likes) || 0);
  }, [likes]);

  // Check if user has liked this blog on component mount
const checkLikeStatus = useCallback(async () => {
    if (!ctx.userId || !blogId) return;
    
    try {
      const response = await fetch(`${FINNTECH_API_URL}/checkLike/${blogId}/${ctx.userId}`);
      const data = await response.json();

      setIsLiked(data.hasLiked);
    } catch (error) {
      console.error('Error checking like status:', error);
    }
  }, [blogId, ctx.userId]);

  // Check like status on mount and when blogId or userId changes
  useEffect(() => {
    checkLikeStatus();
  }, [checkLikeStatus]);

  const handleClick = () => {
    window.location.href = `/blogs/${title.replace(/ /g, '-')}`;
  };

  const handleLike = async (e) => {
    e.stopPropagation(); // Prevent blog click when clicking heart

    try {
      const endpoint = isLiked ? 'unlikeBlog' : 'likeBlog';
      const response = await fetch(`${FINNTECH_API_URL}/${endpoint}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          blogId: blogId,
          userId: ctx.userId
        })
      });

      if (response.ok) {
        setIsLiked(!isLiked);
        setLikeCount(prev => isLiked ? prev - 1 : prev + 1);
      }
    } catch (error) {
      console.error('Error updating like:', error);
    }
  };

  return (
    <div 
      className="blog-item" 
      onClick={handleClick}
      style={{ cursor: 'pointer' }}
    >
      <div className="blog-item-content">
        <h3 className="blog-item-title">{title}</h3>
        <p className="blog-item-date">{created_at}</p>
        <p className="blog-item-author">{author}</p>
        <p className="blog-item-likes">{likeCount} likes</p>
      </div>
      <div 
        className={`heart-icon ${isLiked ? 'liked' : ''}`}
        onClick={handleLike}
      >
        <HeartIcon isLiked={isLiked}/>
      </div>
    </div>
  );
};

const BlogList = ({ maxDisplay = 5 }) => {
  const [activeTab, setActiveTab] = useState('newest');
  const [currentPage, setCurrentPage] = useState(1);

  const [blogItems, setBlogs] = useState([
    { title: 'Industry insights', created_at: '2024-03-15', author: 'Author Name', likes: 445, body: 'testing' },
  ]);

  const fetchBlogsHandler = useCallback(async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/getallblogs`);
      const data = await response.json();
      if (!response.ok) {
        throw new Error();
      }

      // console.log(data);

      const loadedBlogs = [];

      // dynamically render blogs from source
      for (const key in data) {
        loadedBlogs.push({
          blogId: data[key].id,
          title: data[key].title,
          created_at: new Date(data[key].created_at).toLocaleDateString(),
          author: data[key].author,
          likes: parseInt(data[key].likes)
        });
      }
      // console.log(loadedBlogs);

      setBlogs(loadedBlogs);
    } catch (error) {
      console.log(error.message);
    }
  }, []);

  //Render once
  useEffect(() => {
    fetchBlogsHandler();
  }, [fetchBlogsHandler]);

  const sortedBlogItems = [...blogItems].sort((a, b) => {
    if (activeTab === 'newest') {
      return new Date(b.created_at) - new Date(a.created_at);
    } else {
      return b.likes - a.likes;
    }
  });

  const totalPages = Math.ceil(blogItems.length / maxDisplay);
  const startIndex = (currentPage - 1) * maxDisplay;
  const displayedItems = sortedBlogItems.slice(startIndex, startIndex + maxDisplay);
  const showMoreButton = blogItems.length > maxDisplay && maxDisplay === 5;

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handlePreviousPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  return (
    <div className="page-container">
      <div className="blog-list-container">
        <div className="toggle-buttons">
          <button
            className={`toggle-button ${activeTab === 'newest' ? 'active' : ''}`}
            onClick={() => {
              setActiveTab('newest');
              setCurrentPage(1);
            }}
          >
            Newest
          </button>
          <button
            className={`toggle-button ${activeTab === 'popular' ? 'active' : ''}`}
            onClick={() => {
              setActiveTab('popular');
              setCurrentPage(1);
            }}
          >
            Popular
          </button>
        </div>
        <div>
          {displayedItems.map((item, index) => (
            <BlogItem key={index} {...item} />
          ))}
        </div>
        {maxDisplay === 5 ? (
          showMoreButton && (
            <div className="show-more-container">
              <a href="/blogs" className="show-more-button">
                Show More Blogs
              </a>
            </div>
          )
        ) : (
          <div className="pagination-container">
            <button 
              className="pagination-button"
              onClick={handlePreviousPage}
              disabled={currentPage === 1}
            >
              Previous Page
            </button>
            <span className="page-indicator">
              Page {currentPage} of {totalPages}
            </span>
            <button 
              className="pagination-button"
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
            >
              Next Page
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default BlogList;