import React from 'react';

import NavBar from "../components/NavBar";

const Contact = () => {
    
    
   return (
    <>
    <NavBar/>
    <h1>Contact Page</h1>
   </>
   )

}
    
export default Contact;