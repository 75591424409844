import React from 'react';
import '../assets/styles/Footer.css';

const Footer = () => {
  return (
    <>
    <footer className="footer-container">
      <div className="logo" onClick={() => window.location.href = '/'} style={{ cursor: 'pointer' }}>
        <img src="/Logo4.png" alt="Finn Tech Logo" />
      </div>
      <nav className="nav-links">
        <a href="/login">Login</a>
        <a href="/signup">Sign Up</a>
        <a href="/blogs">Blog</a>
        <a href="/contact">Contact</a>
        <a href="/pricing">Pricing</a>
        <a href="/about">About</a>
      </nav>
      <div className="social-media">
        <a href="https://www.youtube.com/@TheFinnTech" target="_blank" rel="noopener noreferrer">
          <img src="/youtube-icon.png" alt="YouTube" />
        </a>
        <a href="https://www.linkedin.com/company/finn-tech-llc/" target="_blank" rel="noopener noreferrer">
          <img src="/linkedin-icon.png" alt="LinkedIn" />
        </a>
        <a href="https://x.com/TheFinnTech" target="_blank" rel="noopener noreferrer">
          <img src="/x-social-media-logo-icon.webp" alt="X" />
        </a>
        <a href="https://www.instagram.com/thefinntech/?hl=en" target="_blank" rel="noopener noreferrer">
          <img src="/instagram.webp" alt="Instagram" />
        </a>
      </div>
    </footer>

      <div className="disclaimer-content">
        <a href="#" className="disclaimer-text">
        © 2024 This content, which contains security-related opinions and/or information, is provided for informational purposes only and should not be relied upon in any manner as professional advice, or an endorsement of any practices, products or services. There can be no guarantees or assurances that the views expressed here will be applicable for any particular facts or circumstances, and should not be relied upon in any manner. You should consult your own advisers as to legal, business, tax, and other related matters concerning any investment. The commentary in this "post" (including any related blog, podcasts, videos, and social media) reflects the personal opinions, viewpoints, and analyses of the Finn Tech LLC employees providing such comments, and should not be regarded the views of Finn Tech LLC or its respective affiliates or as a description of advisory services provided by Finn Tech LLC or performance returns of any Finn Tech LLC client. References to any securities or digital assets, or performance data, are for illustrative purposes only and do not constitute an investment recommendation or offer to provide investment advisory services. Charts and graphs provided within are for informational purposes solely and should not be relied upon when making any investment decision. Past performance is not indicative of future results. The content speaks only as of the date indicated. Any projections, estimates, forecasts, targets, prospects, and/or opinions expressed in these materials are subject to change without notice and may differ or be contrary to opinions expressed by others. Finn Tech LLC receives payment from various entities for advertisements in affiliated content. Inclusion of such advertisements does not constitute or imply endorsement, sponsorship or recommendation thereof, or any affiliation therewith, by the Content Creator or by Finn Tech LLC or any of its employees. Investments in securities involve the risk of loss.
        </a>
      </div>

    </>
  );
};

export default Footer;