import React, { useState, useEffect, useContext } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import "../assets/styles/BlogPostCreation.css";
import AuthLogin from '../profile/auth-login';

// Enum for content block types
const CONTENT_BLOCK_TYPES = {
  TEXT: 'text',
  IMAGE: 'image',
  CHART: 'chart'
};

const BlogPostCreation = () => {
  const ctx = useContext(AuthLogin);

  const [title, setTitle] = useState('');
  const [author, setAuthor] = useState('');
  const [userId, setUserId] = useState('');
  const [contentBlocks, setContentBlocks] = useState([
    { type: CONTENT_BLOCK_TYPES.TEXT, content: '', id: Date.now() }
  ]);
  const [existingImages, setExistingImages] = useState([]);
  const [availableCharts, setAvailableCharts] = useState([]);
  const [draggedItem, setDraggedItem] = useState(null);
  const FINNTECH_API_URL = process.env.REACT_APP_API_URL;
  const FINNTECH_GRAPHICS_URL = process.env.REACT_APP_GRAPHICS_URL;

  // Fetch existing resources
  useEffect(() => {
    const fetchResources = async () => {
      try {
        setAuthor(ctx.fullName);
        setUserId(ctx.userId);
        
        // Fetch charts
        const chartsResponse = await fetch(`${FINNTECH_API_URL}/getallCharts`);
        const chartsData = await chartsResponse.json();
        setAvailableCharts(chartsData);
  
        // Fetch existing images
        console.log(FINNTECH_API_URL);
        const imagesResponse = await fetch(`${FINNTECH_API_URL}/blogImages`);
        const imagesData = await imagesResponse.json();
        setExistingImages(imagesData);
      } catch (error) {
        console.error('Error fetching resources:', error);
      }
    };
  
    fetchResources();
  }, [ctx.fullName, FINNTECH_API_URL]);

  // Drag and Drop handlers
  const handleDragStart = (e, index) => {
    setDraggedItem(index);
    e.currentTarget.style.opacity = '0.4';
  };

  const handleDragEnd = (e) => {
    e.currentTarget.style.opacity = '1';
    setDraggedItem(null);
    e.currentTarget.classList.remove('drag-over');
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.currentTarget.classList.add('drag-over');
  };

  const handleDragLeave = (e) => {
    e.currentTarget.classList.remove('drag-over');
  };

  const handleDrop = (e, dropIndex) => {
    e.preventDefault();
    e.currentTarget.classList.remove('drag-over');
    
    if (draggedItem === null) return;
    
    const newBlocks = [...contentBlocks];
    const draggedBlock = newBlocks[draggedItem];
    
    newBlocks.splice(draggedItem, 1);
    newBlocks.splice(dropIndex, 0, draggedBlock);
    
    setContentBlocks(newBlocks);
  };

  // Add a new content block
  const addContentBlock = (position) => {
    const newBlock = {
      type: CONTENT_BLOCK_TYPES.TEXT,
      content: '',
      id: Date.now()
    };

    setContentBlocks(prev => {
      const newBlocks = [...prev];
      newBlocks.splice(position + 1, 0, newBlock);
      return newBlocks;
    });
  };

  // Update a content block
  const updateContentBlock = (id, updates) => {
    setContentBlocks(prev => 
      prev.map(block => 
        block.id === id ? { ...block, ...updates } : block
      )
    );
  };

  // Remove a content block
  const removeContentBlock = (id) => {
    setContentBlocks(prev => prev.filter(block => block.id !== id));
  };

  // Render appropriate input based on block type
  const renderContentBlockInput = (block) => {
    switch (block.type) {
      case CONTENT_BLOCK_TYPES.TEXT:
        return (
          <ReactQuill 
            value={block.content}
            onChange={(content) => 
              updateContentBlock(block.id, { content })
            }
            modules={{
              toolbar: [
                [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
                ['bold', 'italic', 'underline', 'strike'],
                [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                ['link', 'image'],
                ['clean']
              ]
            }}
          />
        );

      case CONTENT_BLOCK_TYPES.IMAGE:
        return (
          <div>
            <select 
              value={block.imageId || ''}
              onChange={(e) => {
                const selectedImage = existingImages.find(img => img.id === Number(e.target.value));
                updateContentBlock(block.id, { 
                  imageId: Number(e.target.value),
                  imageUrl: selectedImage ? selectedImage.url : null
                });
              }}
            >
              <option value="">Select an image</option>
              {existingImages.map((image) => (
                <option key={image.id} value={image.id}>
                  {image.name}
                </option>
              ))}
            </select>
            
            {block.imageUrl && (
              <div className="image-preview">
                <img 
                  src={block.imageUrl}  // This will now be a local path like /blogImages/filename.jpg
                  alt="Selected" 
                  style={{ maxWidth: '300px', marginTop: '10px' }} 
                />
              </div>
            )}
      
            <div className="image-upload">
              <input 
                type="file" 
                accept="image/*"
                onChange={async (e) => {
                  const file = e.target.files[0];
                  if (!file) return;
      
                  const formData = new FormData();
                  formData.append('image', file);
      
                  try {
                    const response = await fetch(`${FINNTECH_API_URL}/upload-image`, {
                      method: 'POST',
                      body: formData,
                    });
                  
                    if (!response.ok) {
                      throw new Error('Image upload failed');
                    }
                  
                    const newImage = await response.json();
                    
                    // Update the content block with the new image
                    updateContentBlock(block.id, {
                      imageId: newImage.id,
                      imageUrl: newImage.url,  // This will be /blogImages/filename.jpg
                    });
                    
                    // Add to existing images list
                    setExistingImages(prev => [...prev, newImage]);
                  } catch (error) {
                    console.error('Image upload failed:', error);
                    alert('Failed to upload image');
                  }
                }}
              />
            </div>
          </div>
        );

      case CONTENT_BLOCK_TYPES.CHART:
        return (
          <div>
            <select 
              value={block.chartId || ''}
              onChange={(e) => {
                const selectedChart = availableCharts.find(chart => chart.id === Number(e.target.value));
                updateContentBlock(block.id, { 
                  chartId: Number(e.target.value),
                  chartRoute: selectedChart ? selectedChart.url : null  // Store the route for preview
                });
              }}
            >
              <option value="">Select a chart</option>
              {availableCharts.map((chart) => (
                <option key={chart.id} value={chart.id}>
                  {chart.title}
                </option>
              ))}
            </select>
      
            {/* Chart Preview */}
            {block.chartRoute && (
              <div className="chart-preview">
                <iframe
                  src={`${block.chartRoute}`}
                  title="Chart Preview"
                  width="100%"
                  height="400px"
                  frameBorder="0"
                  style={{ marginTop: '10px', border: '1px solid #ddd', borderRadius: '4px' }}
                />
              </div>
            )}
          </div>
        );

      default:
        return null;
    }
  };

  // Submit blog post
  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      const postData = {
        title,
        user_id: userId,
        author: author || 'Anonymous',
        contentBlocks: contentBlocks.map((block, index) => ({
          type: block.type,
          content: block.type === CONTENT_BLOCK_TYPES.TEXT ? block.content : null,
          imageId: block.type === CONTENT_BLOCK_TYPES.IMAGE ? block.imageId : null,
          chartId: block.type === CONTENT_BLOCK_TYPES.CHART ? block.chartId : null,
          order: index
        })),
        created_at: new Date().toISOString()
      };

      const response = await fetch(`${FINNTECH_API_URL}/publishBlog`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(postData),
      });
      
      if (!response.ok) {
        throw new Error('Failed to create blog post');
      }

      alert('Blog post created successfully!');

         // Clear the form
    setTitle('');
    setContentBlocks([
      { type: CONTENT_BLOCK_TYPES.TEXT, content: '', id: Date.now() }
    ]);
    } catch (error) {
      console.error('Blog post creation failed:', error);
      alert('Failed to create blog post');
    }
  };

  return (
    <div className="blog-post-creation-container">
      <h2>Create New Blog Post</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Title</label>
          <input 
            type="text" 
            value={title} 
            onChange={(e) => setTitle(e.target.value)}
            required 
          />
        </div>

        <div>
          <label>Author</label>
          <h3>{author}</h3>
        </div>

        {contentBlocks.map((block, index) => (
          <div 
            key={block.id} 
            className="content-block"
            draggable
            onDragStart={(e) => handleDragStart(e, index)}
            onDragEnd={handleDragEnd}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={(e) => handleDrop(e, index)}
          >
            <div className="drag-handle">⋮⋮</div>
            
            <select 
              value={block.type}
              onChange={(e) => 
                updateContentBlock(block.id, { 
                  type: e.target.value,
                  content: '',
                  imageId: null,
                  chartId: null
                })
              }
            >
              {Object.values(CONTENT_BLOCK_TYPES).map(type => (
                <option key={type} value={type}>
                  {type.charAt(0).toUpperCase() + type.slice(1)}
                </option>
              ))}
            </select>

            {renderContentBlockInput(block)}

            <div className="block-management-buttons">
              <button 
                type="button" 
                onClick={() => addContentBlock(index)}
              >
                Add Block After
              </button>
              {contentBlocks.length > 1 && (
                <button 
                  type="button" 
                  onClick={() => removeContentBlock(block.id)}
                >
                  Remove Block
                </button>
              )}
            </div>
          </div>
        ))}

        <button type="submit">Publish Blog Post</button>
      </form>
    </div>
  );
};

export default BlogPostCreation;