import React, { useEffect, useState } from "react";

// Create an authentication context that now includes user information, role, and subscription status
const AuthLogin = React.createContext({
  isLoggedIn: false,
  user: null, // Object holding details like username and role
  role: null, // User role (e.g., "Admin", "Writer", "User")
  isSubscribed: false, // Subscription status
  userId: null,
  firstTimeLogin: true,
  onLogout: () => {},
  onLogin: (username, fullName, role, userId, firstTimeLogin, userAcceptedTermsAndConditions) => {},
});

export const AuthLoginProvider = (props) => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [user, setUser] = useState(null); // Track user information
  const [fullName, setFullName] = useState(null); 
  const [role, setRole] = useState(null); // Track user role
  const [isSubscribed, setIsSubscribed] = useState(false); // Track subscription status
  const [userId, setUserId] = useState(false); // Track subscription status
  const [firstTimeLogin, setFirstTimeLogin] = useState(true); // track if first time login
  const [userAcceptedTermsAndConditions, setuserAcceptedTermsAndConditions] = useState(false); // 

  useEffect(() => {
    // Check if the user is logged in based on local storage
    const storedUserLoggedInInformation = localStorage.getItem("isLoggedIn");
    const storedUserInfo = JSON.parse(localStorage.getItem("userInfo"));

    if (storedUserLoggedInInformation === "1" && storedUserInfo) {
      setIsLoggedIn(true);
      setUser(storedUserInfo.username);
      setFullName(storedUserInfo.fullName);
      setRole(storedUserInfo.role);
      // setIsSubscribed(storedUserInfo.isSubscribed);
      setUserId(storedUserInfo.userId);
      setFirstTimeLogin(storedUserInfo.firstTimeLogin);
      setuserAcceptedTermsAndConditions(storedUserInfo.userAcceptedTermsAndConditions);
    }
  }, []);

  const logoutHandler = () => {
    // Clear all user-related data on logout
    localStorage.removeItem("isLoggedIn");
    localStorage.removeItem("userInfo");
    setIsLoggedIn(false);
    setUser(null);
    setFullName(null);
    setRole(null);
    // setIsSubscribed(false);
    setUserId(null);
  };

  const loginHandler = (username, fullName, role, userId, firstTimeLogin, userAcceptedTermsAndConditions) => {
    // Assume this function validates the user and retrieves role and subscription status
    const fetchedUser = {
      username: username,
      fullName: fullName,
      role: role, // Example role - replace with actual data from login
      userId: userId,
      firstTimeLogin: firstTimeLogin,
      userAcceptedTermsAndConditions: userAcceptedTermsAndConditions
    };

    // console.log(fetchedUser);

    // Store login status and user info in local storage
    localStorage.setItem("isLoggedIn", "1");
    localStorage.setItem("userInfo", JSON.stringify(fetchedUser));
    
    // Update state with user info
    setIsLoggedIn(true);
    setUser(fetchedUser.username);
    setFullName(fetchedUser.fullName);
    setRole(fetchedUser.role);
    setUserId(fetchedUser.userId);
    // setIsSubscribed(fetchedUser.isSubscribed);
    setFirstTimeLogin(fetchedUser.firstTimeLogin);
    setuserAcceptedTermsAndConditions(fetchedUser.userAcceptedTermsAndConditions);
  };

  return (
    <AuthLogin.Provider
      value={{
        isLoggedIn: isLoggedIn,
        user: user,
        fullName: fullName,
        role: role,
        isSubscribed: isSubscribed,
        userId: userId,
        firstTimeLogin: firstTimeLogin,
        userAcceptedTermsAndConditions: userAcceptedTermsAndConditions,
        setuserAcceptedTermsAndConditions: setuserAcceptedTermsAndConditions,
        setFirstTimeLogin: setFirstTimeLogin,
        onLogout: logoutHandler,
        onLogin: loginHandler,
      }}
    >
      {props.children}
    </AuthLogin.Provider>
  );
};

export default AuthLogin;
