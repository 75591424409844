import React, { useContext, useState } from "react";
import LoggedInHeader from "../components/LoggedInHeader";
import "../assets/styles/SubmitIdea.css";
import AuthLogin from "../profile/auth-login";

const SubmitIdea = () => {
    const [idea, setIdea] = useState("");
    const ctx = useContext(AuthLogin);

    const handleSubmit = (e) => {
        e.preventDefault();
        // Add your submission logic here
        // console.log("Submitted idea:", idea);
        submitIdeaHandler();
        setIdea("");
    };

    const submitIdeaHandler = async(event) => {
        // Create request body
        const requestBody = {
            "idea": idea
        };

        const url = `${process.env.REACT_APP_API_URL}/submitIdea/${ctx.userId}`;
        const response = await fetch(url, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
          },
          body: JSON.stringify(requestBody)
        });
    };

    return (
        <>
            <LoggedInHeader />
            <div className="submit-idea-container">
                <h2 className="submit-idea-title">
                    Type your idea for new analytics or how to improve the site.
                </h2>
                <form onSubmit={handleSubmit} className="submit-idea-form">
                    <textarea
                        value={idea}
                        onChange={(e) => setIdea(e.target.value)}
                        className="idea-textarea"
                        placeholder="Value"
                    />
                    <button type="submit" className="submit-button">
                        Submit Idea
                    </button>
                </form>
            </div>
        </>
    );
};

export default SubmitIdea;