// src/components/LoggedInHeader.js
import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import '../assets/styles/LoggedInHeader.css';
import logo from '../assets/images/logo.png';
import reportBug from '../assets/images/Bug.png';
import user from '../assets/images/User.png';
import ReportBugPopup from './ReportBugPopup';
import AccountDropdown from './AccountDropdown';

const LoggedInHeader = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  
  // Navigation items with their paths
  const navItems = [
    { name: 'My Dashboard', path: '/my-dashboard' },
    { name: 'Add Charts', path: '/add-charts' },
    { name: 'Blog', path: '/blogged-in' },
    { name: 'Learn', path: '/learn' },
    { name: 'Live Charts', path: '/live-charts' },
    { name: 'Submit Idea', path: '/submit-idea' }
  ];

  return (
    <div className="header-container">
      <div className="header-content">
        {/* Logo Section */}
        <div 
          className="logo-section" 
          onClick={() => navigate('/')} 
          style={{ cursor: 'pointer' }}
        >
          <img src={logo} alt="FINNTECH" className="logged-in-logo" />
        </div>
        
        {/* Navigation Links */}
        <nav className="nav-links">
          {navItems.map((item) => (
            <div
              key={item.path}
              className={`nav-item ${location.pathname === item.path ? 'selected' : ''}`}
              onClick={() => navigate(item.path)}
            >
              {item.name}
            </div>
          ))}
        </nav>
        
        {/* Right Icons */}
        <div className="right-icons">
          <div className="icon-container">
            <img 
              src={reportBug} 
              alt="Report Bug" 
              className="header-icon"
              onClick={() => setIsPopupOpen(true)}
            />
          </div>
          <div className="icon-container">
            <img 
              src={user} 
              alt="User Profile" 
              className="header-icon"
              onClick={() => setIsDropdownOpen(!isDropdownOpen)}
            />
            {isDropdownOpen && <AccountDropdown />}
          </div>
        </div>
      </div>
      
      {isPopupOpen && (
        <ReportBugPopup onClose={() => setIsPopupOpen(false)} />
      )}
    </div>
  );
};

export default LoggedInHeader;